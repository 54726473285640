export const FiltersIcon = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1805_4026)">
        <path
          d="M17.581 3.5H0.419006C0.188006 3.5 0 3.164 0 2.75C0 2.336 0.187006 2 0.419006 2H17.582C17.813 2 18.001 2.336 18.001 2.75C18.001 3.164 17.813 3.5 17.581 3.5Z"
          fill="#3B444A"
        />
        <path
          d="M17.581 12.5H0.419006C0.188006 12.5 0 12.164 0 11.75C0 11.336 0.187006 11 0.419006 11H17.582C17.813 11 18.001 11.336 18.001 11.75C18.001 12.164 17.813 12.5 17.581 12.5Z"
          fill="#3B444A"
        />
        <path
          d="M5.4995 1.13563e-05C4.92111 0.000119186 4.36059 0.200758 3.91356 0.56776C3.46653 0.934763 3.1607 1.44542 3.04796 2.01271C2.93522 2.58 3.02266 3.1688 3.29539 3.67884C3.56813 4.18889 4.00917 4.58857 4.54356 4.80983C5.07795 5.03108 5.67261 5.0602 6.22606 4.89222C6.77951 4.72424 7.25758 4.36952 7.57884 3.88856C7.9001 3.40761 8.04467 2.83024 7.9879 2.25465C7.93113 1.67906 7.67647 1.14087 7.26744 0.731945C7.03581 0.499056 6.76025 0.314505 6.45677 0.188854C6.15329 0.0632036 5.82796 -0.000982011 5.4995 1.13563e-05Z"
          fill="#3B444A"
        />
        <path
          d="M14.269 9.73341C13.8603 9.32413 13.3224 9.0693 12.7468 9.01222C12.1712 8.95513 11.5937 9.09928 11.1125 9.4203C10.6314 9.74132 10.2764 10.2193 10.1081 10.7727C9.93987 11.3261 9.96868 11.9207 10.1897 12.4552C10.4107 12.9897 10.8101 13.431 11.32 13.704C11.83 13.977 12.419 14.0648 12.9863 13.9523C13.5537 13.8398 14.0644 13.5339 14.4316 13.087C14.7988 12.6402 14.9996 12.0799 15 11.5015C15.0008 11.1731 14.9367 10.8478 14.8113 10.5443C14.6858 10.2409 14.5015 9.96529 14.269 9.73341Z"
          fill="#3B444A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1805_4026">
          <rect width="18" height="14" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
