const TrashIcon = ({ color = "currentColor", width = "12px", height = "12px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9.33594L9.28175 23.916C9.4187 25.4737 10.7371 26.6693 12.3179 26.6693H19.6821C21.2629 26.6693 22.5813 25.4737 22.7182 23.916L24 9.33594"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.33594V8.24503C12 6.63838 13.5918 5.33594 15.5556 5.33594H16.4444C18.4082 5.33594 20 6.63838 20 8.24503V9.33594"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33594 9.33594H26.6693"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
