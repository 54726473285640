const ErrorIcon = ({}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" stroke="#D70000" strokeWidth="2" />
    <path
      d="m16.5 16.5-9-9m9 0-9 9"
      stroke="#D70000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ErrorIcon;
