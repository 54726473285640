export const PlusIcon = ({ color = "currentColor", width = "10px", height = "10px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23" fill="white" stroke={color} strokeWidth="2" />
      <path
        d="M24 38.1417V9.85742M38.1421 23.9996H9.85786"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
