export const ArrowsUpDown = () => {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.57143L5.5 1L10 5.57143M10 12.4286L5.5 17L1 12.4286"
        stroke="#3B444A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
