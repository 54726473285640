export function LinkedInIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33294 2.60263C2.19347 2.60263 2.71689 2.02002 2.71689 1.30132C2.70025 0.564616 2.19458 0 1.36732 0C0.540051 0 0 0.582612 0 1.30132C0 2.02002 0.523417 2.60151 1.33294 2.60263ZM0.067645 3.62839V11.9987H2.63261V3.62839H0.067645ZM4.09863 3.63021C4.13301 4.38379 4.16628 5.25658 4.16628 6.30033L4.16739 6.29921V11.9994H6.73235V7.17313C6.73235 6.93356 6.74899 6.69399 6.81663 6.52303C7.00293 6.04389 7.42433 5.54788 8.13293 5.54788C9.06111 5.54788 9.43261 6.28346 9.43261 7.36208V12.0005H11.9976V7.0539C11.9976 4.58961 10.7323 3.44238 9.04448 3.44238C7.66053 3.44238 6.83326 4.24657 6.49615 4.79431H6.44514L6.32648 3.63021H4.09863Z"
        fill="currentColor"
      />
    </svg>
  );
}
