const WarningIcon = ({ size = 24 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11" stroke="#F2A725" strokeWidth="2" />
    <path d="M12 5v10M12 18.5v.5" stroke="#F2A725" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default WarningIcon;
