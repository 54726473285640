export const ArtworkPreviewIcon = ({ width = 26, height = 26, stroke = "#5B6771" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 23L8 27L27 27L27 7.5L20.4688 0.999999L8 1L8 11"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 1L20 8L27 8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.8449 17.0753C3.8449 20.4303 6.56561 23.1506 9.92245 23.1506C13.2793 23.1506 16 20.4303 16 17.0753C16 13.7203 13.2793 11 9.92245 11C6.56561 11 3.8449 13.7203 3.8449 17.0753Z"
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        d="M1.68985 26.7232C1.31095 27.125 0.69662 27.125 0.317714 26.7232C-0.0611907 26.3214 -0.0611906 25.6699 0.317714 25.2681L1.68985 26.7232ZM6.00663 22.1455L1.68985 26.7232L0.317714 25.2681L4.63449 20.6904L6.00663 22.1455Z"
        fill={stroke}
      />
    </svg>
  );
};
