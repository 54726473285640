const CaretIcon = ({ fillColor = "#989DA0", width = "10", height = "6" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        fill={fillColor}
        d="M5.002 4.191 8.783.221a.688.688 0 0 1 1.01 0 .783.783 0 0 1 0 1.062L5.507 5.782a.69.69 0 0 1-.985.021L.208 1.286A.77.77 0 0 1 0 .755.77.77 0 0 1 .208.223a.688.688 0 0 1 1.01 0l3.784 3.968z"
      />
    </svg>
  );
};

export default CaretIcon;
