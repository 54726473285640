const SaveIcon = ({ strokeColor = "#3B444A", width = 48, height = 48 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M45.34 12.856h0l.01.01a5.508 5.508 0 0 1 1.65 3.95V41.38c0 3.09-2.532 5.62-5.62 5.62H6.62C3.533 47 1 44.468 1 41.38V6.62C1 3.533 3.532 1 6.62 1h24.563c1.484 0 2.886.586 3.96 1.66L45.34 12.856z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 47V36.294C13 34.482 14.523 33 16.385 33h15.23C33.477 33 35 34.482 35 36.294V47M13 1v9.771"
    />
  </svg>
);
export default SaveIcon;
