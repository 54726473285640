const UploadIcon = ({ width = 72, height = 48 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 76 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.32 50C67.88 50 74 43.425 74 35.302c0-6.885-4.392-12.609-10.296-14.233 0-.31.036-.58.036-.89C63.74 10.16 56.18 2 46.82 2c-6.48 0-12.132 3.945-14.94 9.708l-.036-.038c-1.872-1.509-4.212-2.398-6.732-2.398-6.048 0-10.944 5.105-11.268 11.487h-.036C7.148 21.726 2 27.876 2 35.302 2 43.386 8.12 50 15.68 50h16.308V31.589h-8.136L37.64 15.46 51.428 31.59h-8.136V50H60.32z"
      stroke="#5B6771"
      strokeWidth="3"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadIcon;
