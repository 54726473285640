const SuccessTickIcon = ({ strokeColor = "#4CB260", size = 48 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 12c0 6.078-4.922 11-11 11S1 18.078 1 12 5.922 1 12 1s11 4.922 11 11z"
      stroke={strokeColor}
      strokeWidth="2"
    />
    <path
      d="m5.695 12.296 4.002 4.206 7.998-8.4"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SuccessTickIcon;
