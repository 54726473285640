export default function ThunderIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.46396 18.0038L3.46398 18.0038C3.5903 18.0578 3.73172 18.065 3.86287 18.0241C3.99402 17.9833 4.10631 17.897 4.1796 17.7808L4.17963 17.7808L10.508 7.72636L10.5081 7.7263C10.5629 7.63894 10.5933 7.5385 10.5962 7.43541C10.5991 7.33233 10.5743 7.23035 10.5244 7.14007C10.4746 7.04979 10.4015 6.9745 10.3127 6.92202C10.2239 6.86954 10.1227 6.84178 10.0196 6.84162H10.0195H5.85937L7.41538 0.668265L7.41544 0.668022C7.44827 0.534944 7.43272 0.394527 7.37155 0.271863C7.31039 0.149197 7.2076 0.0522759 7.08156 -0.00158235C6.95552 -0.0554407 6.81443 -0.0627268 6.68351 -0.0221387C6.55259 0.0184493 6.44036 0.104267 6.36689 0.219979L6.36679 0.220147L0.0383642 10.2746L0.0383246 10.2746C-0.0164792 10.362 -0.0469001 10.4624 -0.0497754 10.5655C-0.0526507 10.6686 -0.027872 10.7706 0.0219766 10.8609C0.0718256 10.9511 0.144929 11.0264 0.233705 11.0789C0.322481 11.1314 0.423689 11.1592 0.526816 11.1593H0.526891H4.68704L3.13104 17.3317L3.13104 17.3317C3.09749 17.465 3.11258 17.606 3.1736 17.7291C3.23462 17.8523 3.33758 17.9497 3.46396 18.0038ZM1.57239 10.0049L5.40636 3.91357L4.55872 7.27635L4.55872 7.27636C4.53727 7.36156 4.53554 7.45053 4.55365 7.5365C4.57176 7.62248 4.60924 7.70318 4.66325 7.77249C4.71725 7.84179 4.78634 7.89786 4.86528 7.93644C4.94422 7.97501 5.03091 7.99507 5.11877 7.9951H5.11879H8.97403L5.14005 14.0864L5.98769 10.7236L5.98769 10.7236C6.00914 10.6384 6.01088 10.5495 5.99277 10.4635C5.97466 10.3775 5.93717 10.2968 5.88317 10.2275C5.82917 10.1582 5.76007 10.1021 5.68114 10.0635C5.6022 10.025 5.5155 10.0049 5.42764 10.0049H1.57239Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.1"
      />
    </svg>
  );
}
