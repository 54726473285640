export const TickIcon = ({
  fill = "#fff",
  width = "32px",
  height = "32px",
  strokeWidth = "3",
  stroke = "#07bc0c",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 32c8.84 0 16-7.16 16-16S24.84 0 16 0 0 7.16 0 16s7.16 16 16 16z" fill={fill} />
      <path
        d="M7.602 16.393 12.938 22l10.664-11.2"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
