export const OpenIcon = ({ color = "#3B444A", width = "10px", height = "10px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 1.172v16M17 9.172H1" stroke={color} strokeWidth="1" strokeLinecap="round" />
    </svg>
  );
};
